/* Vendor files ------------------------------------ */

import hideOnScroll from './partial/hide-on-scroll.js';

// Move header top links on mobile
$(document).ready(function () {
	function movePaste() {
		var currentWidth = window.innerWidth;
		if (currentWidth < 900) {
			$('.header__menu').append($('.header__menu-shop .header__link--shop'));
		} else {
			$('.header__menu-shop').append($('.header__link--shop'));
		}
	}
	movePaste();
	$(window).resize(movePaste);

	function varCreate() {
		var headerMobile = document.querySelector('.header__wrap');
		var headerHeight = headerMobile.clientHeight;

		var header = document.querySelector('.mainHeader');
		var height = header.clientHeight;

		document.documentElement.setAttribute(
			'style',
			`--header-height-mobile: ${headerHeight}px;
		--header-height: ${height}px`
		);
	}
	varCreate();
	$(window).resize(varCreate);
});
